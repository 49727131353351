/* eslint-disable @next/next/no-img-element */
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import LogRocket from 'logrocket';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { destroyCookie, setCookie } from 'nookies';
import Image from 'next/image';

import Head from '@app/components/molecules/Head';
import * as Styled from './styles';
import { useQuery } from '@app/hooks';
import { userUpdate, userReset } from '@app/store/user';
import { intakeReset } from '@app/store/intake';
import { homeownerIntakeReset } from '@app/store/homeownerIntake';
import { buyerIntakeReset } from '@app/store/buyerIntake';
import { progress } from '@app/utils/progressbar';
import { GET_USER } from '@app/queries/getUser';
import {
  Heading,
  Img,
  CodeInput,
  Text,
  FlexBox,
  Input,
  Button,
} from '@app/components/atoms';
import TemplateContainer from '@app/components/atoms/TemplateContainer';
import * as Sentry from '@sentry/nextjs';
import LogoOnly from '@public/images/logo-only.svg';
import TableImage from '@public/images/table-image-sign-up.png';

type LogInProps = {
  isModal?: boolean;
  closeModal?: () => void;
  setModalScreen?: (value: string) => void;
};

const clearCookies = () => {
  const cookiesToClear = [
    // Application cookies
    'trustyRole',
    'buyerCriteriaId',
    'claimedHomeId',
    'transition',
    'intakePath',
    'agentSignUpComplete',

    // Auth cookies
    'accessToken',
    'idToken',
    'refreshToken',
  ];
  cookiesToClear.forEach((cookie) => destroyCookie({}, cookie, { path: '/' }));
};

const LogIn = ({ isModal, closeModal, setModalScreen }: LogInProps) => {
  const router = useRouter();
  const codeRef = useRef<any>(null);
  const cognitoUser = useRef(null);
  const theme = useTheme();

  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [immediate, setImmediate] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const dispatch = useDispatch();

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    watch: watchEmail,
  } = useForm();

  const {
    handleSubmit: handleSubmitCode,
    control,
    watch: watchCode,
    setValue: setCodeValue,
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const allWatchEmailFields = watchEmail();
  const allWatchCodeFields = watchCode();

  // Extract redirect parameter from URL
  const { redirect, session: sessionParam } = router.query;

  // Handle session expiration case
  useEffect(() => {
    if (sessionParam === 'expired') {
      setError('Your session has expired. Please log in again.');
    }
  }, [sessionParam]);

  // Countdown timer effect
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [countdown]);

  // Redirect if already authenticated
  useEffect(() => {
    const checkSessionWithTimeout = async (timeout = 5000) => {
      return Promise.race([
        Auth.currentSession(),
        new Promise<never>((_, reject) =>
          setTimeout(
            () => reject(new Error('Session request timed out')),
            timeout,
          ),
        ),
      ]);
    };

    const checkAuthAndRedirect = async () => {
      try {
        const session = await checkSessionWithTimeout();
        if (session.isValid()) {
          // If authenticated, redirect to /my-trusty without clearing anything
          router.push('/my-trusty');
          return; // Prevent further actions if user is authenticated
        }
      } catch (err) {
        // Not logged in or session invalid, so clear session data
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();
        dispatch(userReset());
        dispatch(intakeReset());
        dispatch(homeownerIntakeReset());
        dispatch(buyerIntakeReset());
      } finally {
        setSessionChecked(true);
      }
    };

    checkAuthAndRedirect();

    // OAuth event listener
    window.addEventListener('oauth', handleOauth);

    return () => {
      window.removeEventListener('oauth', handleOauth);
    };
  }, []);

  const handleGetProfile = (data: any) => {
    setCookie(null, 'trustyRole', data.getUser.userType, { path: '/' });

    const isAgent = data.getUser.userType === 'agent';
    const hasAgentRecord = !!(
      data?.getUser?.agent?.license && data?.getUser?.agent?.state
    );

    // Set cookie to track if agent has completed signup
    if (isAgent && !hasAgentRecord) {
      setCookie(null, 'agentSignUpComplete', 'false', { path: '/' });
    }

    dispatch(userUpdate(data.getUser));
    progress.finish();
    LogRocket.identify(data?.getUser?.email);

    if (isModal && closeModal) {
      closeModal();
    } else {
      // Handle redirection logic
      if (
        isAgent &&
        !hasAgentRecord &&
        redirect &&
        (redirect.includes('/my-trusty') ||
          redirect.includes('/account') ||
          redirect.includes('/profile'))
      ) {
        // Redirect to agent details if agent doesn't have license info and was trying to access protected pages
        router.push(
          `/signup/agent-details?redirect=${encodeURIComponent(
            redirect as string,
          )}`,
        );
      } else if (redirect) {
        // Redirect to original destination if provided
        router.push(redirect as string);
      } else {
        // Default redirect to my-trusty
        router.push('/my-trusty/');
      }
    }
    setLoading(false);
  };

  useQuery({
    query: GET_USER,
    onResult: handleGetProfile,
    onFail: (err) => Sentry.captureException(err),
    immediate,
  });

  const clearCodeInput = () => {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
    codeRef.current.state.input.fill('');
  };

  const handleResendCode = async () => {
    if (countdown > 0) return; // Prevent resending if countdown is active

    if (emailSent) {
      setCodeValue('code', '');
      clearCodeInput();
    }
    setError(null);
    setResendCodeLoading(true);
    try {
      cognitoUser.current = await Auth.signIn(
        allWatchEmailFields.email.toLowerCase(),
      );
      setEmailSent(true);
      // Start countdown timer
      setCountdown(60);
    } catch (err) {
      setError("We couldn't find a Trusty account with that email");
    } finally {
      setResendCodeLoading(false);
    }
  };

  const handleOauth = () => {
    setTimeout(() => {
      router.push('/my-trusty');
    }, 1000);
  };

  const onSubmitUser = async () => {
    setError(null);
    setLoading(true);
    if (!allWatchEmailFields.email?.length) {
      setError('Email address required');
      setLoading(false);
    } else if (!emailSent && allWatchEmailFields.email?.length) {
      await handleResendCode();
      setLoading(false);
    } else if (emailSent && allWatchCodeFields.code?.length < 6) {
      setLoading(false);
      setError('Please enter valid verification code');
    } else {
      try {
        await Auth.sendCustomChallengeAnswer(
          cognitoUser.current,
          allWatchCodeFields.code,
        );
        await Auth.currentSession();
        setIsVerified(true);
        setImmediate(true);
        progress.start();
      } catch (err) {
        setError('Please enter valid verification code');
        setLoading(false);
        setIsVerified(false);
      }
    }
  };

  // Get a signup URL that preserves the redirect parameter
  const getSignupUrl = () => {
    if (redirect) {
      return `/signup?redirectAfterSignup=${encodeURIComponent(
        redirect as string,
      )}`;
    }
    return '/signup';
  };

  // Determine if we should show loading state
  const isPageLoading = loading || !sessionChecked;

  // Format countdown text
  const getResendCodeText = () => {
    if (resendCodeLoading) return 'Resending code...';
    if (countdown > 0) return `Resend code (${countdown}s)`;
    return 'Resend code';
  };

  return (
    <div>
      <Head path="/login" title="Login" />
      <main>
        <TemplateContainer isModal={isModal}>
          <Styled.Container>
            <Styled.Content>
              <Styled.LoginContainer row>
                <Styled.LoginLeft>
                  <Styled.Title>Welcome back!</Styled.Title>
                  <Styled.SubTitle>Ready to close more deals?</Styled.SubTitle>
                  {!emailSent ? (
                    <Styled.Form onSubmit={handleSubmitEmail(onSubmitUser)}>
                      <Styled.InputWrapper inputType="email">
                        <Input
                          {...registerEmail('email')}
                          placeholder="Email"
                          $valid={!error}
                          variant="md"
                          required
                          id="emailInput"
                        />
                      </Styled.InputWrapper>
                      <Text
                        style={{ height: '20px' }}
                        color={theme.colors.ice[250]}
                        textAlign="center"
                      >
                        {error}
                      </Text>
                      <Styled.ContineuButtonContainer
                        justifyCenter
                        itemsCenter
                        marginTop={40}
                        keepStyleOnMobile
                      >
                        <Styled.ContinueButton
                          fontSize={18}
                          width="340px"
                          loading={isPageLoading}
                        >
                          Continue
                        </Styled.ContinueButton>
                      </Styled.ContineuButtonContainer>
                    </Styled.Form>
                  ) : (
                    <Styled.Form onSubmit={handleSubmitCode(onSubmitUser)}>
                      <Text
                        color={'#4F4F4F'}
                        textAlign="left"
                        variant={'lg'}
                        margin={{ top: 20, bottom: 0, left: 0, right: 0 }}
                        style={{ maxWidth: '300px' }}
                      >
                        Check your email for an authentication code from Trusty.
                      </Text>
                      <FlexBox marginTop={20} marginBottom={20}>
                        <Controller
                          name="code"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <CodeInput
                              ref={codeRef}
                              code={value}
                              setCode={onChange}
                              withBorder={true}
                            />
                          )}
                        />
                      </FlexBox>
                      <Text
                        style={{ height: '20px' }}
                        color={theme.colors.error}
                        textAlign="center"
                      >
                        {error}
                      </Text>
                      <Text
                        onClick={countdown > 0 ? undefined : handleResendCode}
                        style={{
                          cursor: countdown > 0 ? 'default' : 'pointer',
                          opacity: countdown > 0 ? 0.6 : 1,
                          marginBottom: '20px',
                          marginTop: '20px',
                        }}
                        color={theme.colors.secondary}
                        bold
                        textAlign="left"
                      >
                        {getResendCodeText()}
                      </Text>

                      <FlexBox
                        keepStyleOnMobile
                        marginBottom={30}
                        marginTop={0}
                        itemsCenter
                        row
                        justifyCenter={!emailSent}
                        justifyBetween={emailSent}
                        gap={20}
                      >
                        <Button
                          type="button"
                          onClick={() => {
                            setError('');
                            setEmailSent(false);
                            clearCodeInput();
                            setCountdown(0);
                          }}
                          variant="tertiary"
                        >
                          Back
                        </Button>

                        <Button
                          type="submit"
                          fontSize={16}
                          width="200px"
                          loading={isPageLoading}
                          disabled={isVerified || loading}
                        >
                          Continue
                        </Button>
                      </FlexBox>
                    </Styled.Form>
                  )}

                  <Styled.SignUpText color="#4F4F4F">
                    New to Trusty?{' '}
                    <Link href={getSignupUrl()} passHref>
                      <span>Create an account</span>
                    </Link>
                  </Styled.SignUpText>
                  <Styled.SignUpSubText>
                    <span>Join agents discovering hidden opportunities</span>
                  </Styled.SignUpSubText>
                </Styled.LoginLeft>

                <Styled.LoginRight>
                  <Styled.ImageContainer>
                    <Image
                      src={LogoOnly}
                      alt="Trusty Logo"
                      objectFit="contain"
                      priority
                    />
                  </Styled.ImageContainer>
                </Styled.LoginRight>
              </Styled.LoginContainer>
            </Styled.Content>
          </Styled.Container>
        </TemplateContainer>
      </main>
    </div>
  );
};

interface RightItemProps {
  title: string;
  paragraph: string;
}

export default LogIn;
